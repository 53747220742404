import React from "react";
import { Link } from "react-router-dom";
import SocialMediaFollow from "./SocialMediaFollow";

function Footer() {
    return(
        <div style={{backgroundColor: 'white', marginTop: '20px'}}>

          {/* Start Footer Area */}
          <div className="footer-area">
            <div className="container">
              <div className="footer pt-100 pb-70">
                <div className="row">
                  <div className="col-lg-4 col-sm-6">
                    <div className="logo-area" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                      <a href="/"><img src="assets/img/logo.png" alt=" " /></a>
                      <p>
                        Subscribe to GrandeurSmart newsletter for the latest health tips, updates, and exclusive offers. Enter
                        your
                        email below to join our community and prioritize your well-being."
                      </p>
                      <form className="newsletter-form" data-toggle="validator">
                        <input type="email" className="form-control" placeholder="Your Email" name="EMAIL" required
                          autocomplete="off" />

                        <button className="default-btn" type="submit">
                          Subscribe
                        </button>

                        <div id="validator-newsletter" className="form-result"></div>
                      </form>
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-6">
                    <div className="quick-link" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
                      <h3>Links:</h3>
                      <ul>

                        <li>
                          <Link to='/' className="link-text-deco">
                            <p>Blog</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className="link-text-deco">
                            <p>Integration</p>
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className="link-text-deco">
                            <p>Product</p>
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="helpful-link" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
                      <h3>Address:</h3>
                      <ul>
                        <li>
                          <p>
                            32 Cumberland Dr. Brampton, ON. </p>
                          <p> Canada L6V1W6.</p>
                        </li>
                        
                        <li>
                          <span>Phone:</span><Link className="link-text-deco" to="tel:+19024881641"> +1 902-488-1641</Link>
                        </li>
                        <li>
                          <span>Email:</span><Link className="link-text-deco" to="mailto:info@grandeursmart.ca"> info@grandeursmart.ca</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="helpful-link2" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
                      <p className="footer-contact"> 
                          <span>Links:</span>
                          <ul>
                            
                            <li 
                              className="link-text-deco"
                            >
                              <Link to='/products' className="link-text-deco">
                                <p>Product</p>
                              </Link>
                            </li>
                            <li>
                              <Link to='/' className="link-text-deco">
                              <p>Integration</p>
                              </Link>
                            </li>
                            <li>
                              <Link to='/' className="link-text-deco">
                                <p>Blog</p>
                              </Link>
                            </li>
                          </ul>  

                      </p>
                      <p className="footer-contact"> 
                          <span>Address:</span>
                          <ul>
                            <li>
                              32 Cumberland Dr. Brampton, ON. Canada L6V1W6.
                            </li>
                            
                          </ul>  

                      </p>
                      <p className="footer-contact"> 
                          <span>Phone:</span>
                          <ul>
                            <li>
                              <Link 
                                className="link-text-deco" 
                                to="tel:+19024881641" 
                                > 
                                  +1 902-488-1641
                              </Link>
                            </li>

                          </ul>  

                      </p>
                      <p className="footer-contact"> 
                          <span>Email:</span>
                          <ul>
                            <li>
                            <Link 
                              className="link-text-deco" 
                              to="mailto:info@grandeursmart.ca"
                              
                            > 
                              info@grandeursmart.ca
                            </Link>
                            </li>
                          </ul>  

                      </p>
                    </div>
                    
                  </div>

                  <div className="col-lg-1 col-sm-6">
                    <div className="last" >
                      <Link to='https://play.google.com/store/apps/details?id=com.grandeursmart.home.android'>
                        <img src="assets/img/AppImage/google_white_bg.png" alt="" className="app-store"/>
                      </Link>
                      <img src="assets/img/AppImage/Apple_white_bg.png" alt="" className="app-store" />
                    </div>
                  </div>
                </div>
              </div>
              <SocialMediaFollow />
              <div className="copyright">
                <div className="row">
                  <div className="col-lg-full">
                    <div className="copy">
                      <p>
                        © Copywrite 2024 
                        <a href="yyy" target="_blank"> GrandeurSmart</a>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* End Footer Area */}       
        </div>
    )
}

export default Footer;