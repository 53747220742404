import React from 'react';
import '../SocialMediaFollow.css';

const SocialMediaFollow = () => {
  return (
    <div className="social-media">
      <h2 style={{ fontSize: 'large' }}>Follow Us</h2>
      <ul>
        <li>
          <a href="https://m.facebook.com/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="https://x.com/mainlogixtechca" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/mainlogixtechnology?igsh=ajR0Mm5tdWNlb2pk" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/mainlogix-technology-canada/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaFollow;
