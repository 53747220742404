import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from '../contexts/CartContext'; // Import the CartContext
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function FirstNav() {
    const [isOpen, setIsOpen] = useState(false);
    const { cartItems } = useCart(); // Get cart items from the context
    const itemCount = cartItems.length; // Count the items in the cart

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* Nav Start */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/"><img src="assets/img/logo.png" alt="logo" /></Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleNavbar}
                        aria-controls="navbarSupportedContent"
                        aria-expanded={isOpen ? "true" : "false"}
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/products">Product</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/estate-plans">Real Estate</Link>
                            </li>
                            
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Integration</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/">Blog</Link>
                            </li>
                            
                            
                        </ul>
                        <div>
                        <div className="buttons">
                            <input type="button" value="Sign Up" />
                            <img src="assets/img/Flag_of_Canada.png" style={{marginLeft: "7px"}} width='40px' alt="Canada  Flag" />
                        </div>
                        
                        </div>
                    </div>
                    {itemCount > 0 && (
                        <div className="cart-icon">
                            <Link to="/cart">
                                <img src="assets/img/AppImage/icons8-cart-80.png" alt="Cart" />
                                <span className="item-count">{itemCount}</span>
                            </Link>
                        </div>
                    )}
                </div>
            </nav>
            {/* End of Nav Bar */}
        </div>
    );
}

export default FirstNav;
